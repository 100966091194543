﻿
.form-container {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-direction: column;
  display: flex;
}

.form-title {
  width: 100%;
  height: 25px;
  padding-left: 0px;
  padding-right: 0px;
}

.form-title h3 {
  color: #575252;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.023%; /* 19.953px */
  letter-spacing: -0.375px;
}

.form-container-row {
  padding-left: 0px;
  padding-right: 0px;
}