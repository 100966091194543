﻿.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-sm {
  width: 46px;
  height: 25px; /* Ajusta la altura para igualar a btn-sm */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-sm .slider:before {
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 2px;
}

input:checked + .slider {
  background-color: #3dae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.switch-sm input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}


/* Slider range*/
input[type="range"].slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  height: 12px;
  border-radius: 5px;
}

input[type="range"].slider:hover {
  opacity: 0.7;
}

input[type="range"].slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
  height: 25px;
  border-radius: 50%;
}
