@font-face {
    font-family: 'proAgroFont';
    src: url('../fonts/proAgroFont.eot?2jm6g7');
    src: url('../fonts/proAgroFont.eot?2jm6g7#iefix') format('embedded-opentype'), url('../fonts/proAgroFont.ttf?2jm6g7') format('truetype'), url('../fonts/proAgroFont.woff?2jm6g7') format('woff'), url('../fonts/proAgroFont.svg?2jm6g7#proAgroFont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.pg {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'proAgroFont' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pg-add-outline:before {
    content: "\e967";
}

.pg-credit-card .path1:before {
    content: "\e963";
    color: rgb(77, 77, 77);
}

.pg-credit-card .path2:before {
    content: "\e964";
    margin-left: -1.384765625em;
    color: rgb(255, 255, 255);
}

.pg-credit-card .path3:before {
    content: "\e965";
    margin-left: -1.384765625em;
    color: rgb(255, 255, 255);
}

.pg-credit-card .path4:before {
    content: "\e966";
    margin-left: -1.384765625em;
    color: rgb(255, 255, 255);
}

.pg-credit-card-red .path1:before {
    content: "\e95e";
    color: rgb(248, 112, 120);
}

.pg-credit-card-red .path2:before {
    content: "\e95f";
    margin-left: -1.384765625em;
    color: rgb(243, 83, 93);
}

.pg-credit-card-red .path3:before {
    content: "\e960";
    margin-left: -1.384765625em;
    color: rgb(84, 76, 91);
}

.pg-credit-card-red .path4:before {
    content: "\e961";
    margin-left: -1.384765625em;
    color: rgb(255, 255, 255);
}

.pg-credit-card-red .path5:before {
    content: "\e962";
    margin-left: -1.384765625em;
    color: rgb(255, 255, 255);
}

.pg-credit-card-blue .path1:before {
    content: "\e968";
    color: rgb(124, 163, 230);
}

.pg-credit-card-blue .path2:before {
    content: "\e969";
    margin-left: -1.384765625em;
    color: rgb(75, 142, 249);
}

.pg-credit-card-blue .path3:before {
    content: "\e96a";
    margin-left: -1.384765625em;
    color: rgb(64, 77, 114);
}

.pg-credit-card-blue .path4:before {
    content: "\e96b";
    margin-left: -1.384765625em;
    color: rgb(255, 255, 255);
}

.pg-credit-card-blue .path5:before {
    content: "\e96c";
    margin-left: -1.384765625em;
    color: rgb(255, 255, 255);
}

.pg-duplucate:before {
    content: "\e96d";
}

.pg-power-attorney-letter:before {
    content: "\e95a";
}

.pg-constitutive-act:before {
    content: "\e95b";
}

.pg-proof-address:before {
    content: "\e95c";
}

.pg-official-identification:before {
    content: "\e95d";
}

.pg-arrows:before {
    content: "\e959";
}

.pg-star-badge:before {
    content: "\e955";
}

.pg-money:before {
    content: "\e956";
}

.pg-exclamation-circle:before {
    content: "\e957";
}

.pg-map-marker:before {
    content: "\e958";
}

.pg-rotate:before {
    content: "\e952";
}

.pg-caret-rigth:before {
    content: "\e953";
}

.pg-caret-down:before {
    content: "\e96e";
}

.pg-caret-up:before {
    content: "\e96f";
}

.pg-caret-left:before {
    content: "\e954";
}

.pg-refund:before {
    content: "\e951";
}

.pg-menu-burger-color .path1:before {
    content: "\e94d";
    color: rgb(251, 6, 95);
}

.pg-menu-burger-color .path2:before {
    content: "\e94e";
    margin-left: -0.947265625em;
    color: rgb(5, 182, 211);
}

.pg-menu-burger-color .path3:before {
    content: "\e94f";
    margin-left: -0.947265625em;
    color: rgb(62, 206, 12);
}

.pg-menu-burger:before {
    content: "\e950";
}

.pg-window:before {
    content: "\e94c";
}

.pg-times:before {
    content: "\e948";
}

.pg-refused .path1:before {
    content: "\e949";
    color: none;
}

.pg-refused .path2:before {
    content: "\e94a";
    margin-left: -1em;
    color: rgb(77, 77, 77);
}

.pg-refused .path3:before {
    content: "\e94b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-refused_color .path1:before {
    content: "\e936";
    color: none;
}

.pg-refused_color .path2:before {
    content: "\e942";
    margin-left: -1em;
    color: rgb(251, 6, 95);
}

.pg-refused_color .path3:before {
    content: "\e947";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-search:before {
    content: "\e946";
}

.pg-authorized .path1:before {
    content: "\e937";
    color: none;
}

.pg-authorized .path2:before {
    content: "\e938";
    margin-left: -1em;
}

.pg-authorized .path3:before {
    content: "\e939";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-pending_color .path1:before {
    content: "\e93a";
    color: none;
}

.pg-pending_color .path2:before {
    content: "\e93b";
    margin-left: -1em;
    color: rgb(255, 184, 0);
}

.pg-pending_color .path3:before {
    content: "\e93c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-pending_color .path4:before {
    content: "\e93d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-pending .path1:before {
    content: "\e93e";
    color: none;
}

.pg-pending .path2:before {
    content: "\e93f";
    margin-left: -1em;
}

.pg-pending .path3:before {
    content: "\e940";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-pending .path4:before {
    content: "\e941";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-authorized_color .path1:before {
    content: "\e943";
    color: none;
}

.pg-authorized_color .path2:before {
    content: "\e944";
    margin-left: -1em;
    color: rgb(61, 174, 43);
}

.pg-authorized_color .path3:before {
    content: "\e945";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-effects:before {
    content: "\e935";
}

.pg-move:before {
    content: "\e933";
}

.pg-validate_key:before {
    content: "\e934";
}

.pg-reload:before {
    content: "\e931";
}

.pg-save:before {
    content: "\e932";
}

.pg-print-format:before {
    content: "\e928";
}

.pg-process .path1:before {
    content: "\e929";
    color: rgb(77, 77, 77);
}

.pg-process .path2:before {
    content: "\e92a";
    margin-left: -1em;
    color: rgb(77, 77, 77);
}

.pg-process .path3:before {
    content: "\e92b";
    margin-left: -1em;
    color: rgb(77, 77, 77);
}

.pg-process .path4:before {
    content: "\e92c";
    margin-left: -1em;
    color: rgb(77, 77, 77);
}

.pg-process .path5:before {
    content: "\e92d";
    margin-left: -1em;
    color: rgb(251, 6, 95);
}

.pg-process .path6:before {
    content: "\e92e";
    margin-left: -1em;
    color: rgb(255, 184, 0);
}

.pg-process .path7:before {
    content: "\e92f";
    margin-left: -1em;
    color: rgb(62, 206, 12);
}

.pg-trash:before {
    content: "\e930";
}

.pg-sort:before {
    content: "\e925";
}

.pg-eye:before {
    content: "\e927";
}

.pg-edit:before {
    content: "\e926";
}

.pg-geotech:before {
    content: "\e900";
}

.pg-weather:before {
    content: "\e901";
}

.pg-users:before {
    content: "\e902";
}

.pg-ubications:before {
    content: "\e903";
}

.pg-matrix-travel-perdiem:before {
    content: "\e904";
}

.pg-payment:before {
    content: "\e905";
}

.pg-accidents:before {
    content: "\e906";
}

.pg-finance:before {
    content: "\e907";
}

.pg-clients:before {
    content: "\e908";
}

.pg-policies:before {
    content: "\e909";
}

.pg-drafts:before {
    content: "\e90a";
}

.pg-catalogs:before {
    content: "\e90b";
}

.pg-reports:before {
    content: "\e90c";
}

.pg-campaigns:before {
    content: "\e90d";
}

.pg-work-orders:before {
    content: "\e90e";
}

.pg-travel-allowance:before {
    content: "\e90f";
}

.pg-extraordinary-travel-allowances:before {
    content: "\e910";
}

.pg-reinsurance:before {
    content: "\e911";
}

.pg-contraprestaciones:before {
    content: "\e912";
}

.pg-accidents-reinsurance:before {
    content: "\e913";
}

.pg-flows:before {
    content: "\e914";
}

.pg-turns:before {
    content: "\e915";
}

.pg-tickets:before {
    content: "\e916";
}

.pg-add:before {
    content: "\e917";
}

.pg-Group-274:before {
    content: "\e918";
}

.pg-circle-check:before {
    content: "\e919";
}

.pg-paperclip:before {
    content: "\e91a";
}

.pg-circle-times .path1:before {
    content: "\e91b";
    color: rgb(77, 77, 77);
}

.pg-circle-times .path2:before {
    content: "\e91c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.pg-dashboard:before {
    content: "\e91d";
}

.pg-paperclip-25:before {
    content: "\e91e";
}

.pg-estimate:before {
    content: "\e91f";
}

.pg-upra-rules:before {
    content: "\e920";
}

.pg-share:before {
    content: "\e921";
}

.pg-upload:before {
    content: "\e922";
}

.pg-print:before {
    content: "\e923";
}

.pg-download:before {
    content: "\e924";
}
