﻿/* Estilos para el contenedor principal */
.tab-container {
    background-color: #fff;
    border-radius: 15px;
    border: 0.5px solid #E5E6E8;
    height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative; /* Importante para el indicador de fondo */
    box-shadow: 0px 1px 2px 1px #0000000D;
}

/* Nuevo indicador de fondo */
.background-indicator {
    background-color: #E1FFD7; /* Color de fondo del indicador */
    height: 100%;
    width: 0; /* Inicialmente no visible */
    position: absolute;
    top: 0;
    left: 0;
    /*border-radius: 10px;*/
    transition: width 0.3s ease, left 0.3s ease; /* Transiciones suaves */
    z-index: 0; /* Detrás del contenido */
}

.background-indicator::before {
    content: "";
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0;
    height: 0;
    border-bottom: 30px solid transparent;
    border-top: 30px solid transparent;
    z-index: 1;
    border-left: 15px solid #FFF;
}

.background-indicator::after {
    content: "";
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    bottom: 0px;
    right: -14px;
    width: 0;
    height: 0;
    border-bottom: 30px solid transparent;
    border-top: 30px solid transparent;
    z-index: 1;
    border-left: 15px solid #E1FFD7;
}

.background-indicator.hide-before::before {
    display: none;
}

.background-indicator.hide-after::after {
    display: none;
}


/* Estilos para las partes del encabezado */
.tab-item {
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Opcional: Mejora la indicación de que es clickable */
    z-index: 1; /* Asegura que el contenido esté encima del indicador de fondo */
}
/*.tab-item .active {
  background-color: #E1FFD7;*/ /* Color de fondo de la primera parte */
/*}*/

.tab-item.item-complete a {
  background-color: #E1FFD7; /* Color de fondo de la primera parte */
}

.triangle {
  position: absolute;
  bottom: 0px; /* Cubre un poco del div siguiente */
  right: -14px;
  width: 0;
  height: 0;
  border-bottom: 30px solid transparent;
  border-top: 30px solid transparent;
  z-index: 1;
  border-left: 14px solid #FFF;
}

.tab-item .active .triangle, .tab-item.item-complete .triangle {
    border-left: 15px solid #E1FFD7; /* Color de fondo del triángulo */
    transition:  0.3s ease;
}

.number-tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Hace que el div sea un círculo */
  border: 2px solid #3DAE2B;
  margin-right: 5px; /* Margen derecho para separación */
}

.item-complete .number-tab {
  background-size: cover;
  background-position: center;
  color: transparent;
  background-color: #3DAE2B;
  border: 2px solid #3ECE0C;
}

.tab-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-left: .5rem;
}

.item-midle .tab-link, .item-end .tab-link {
  padding-left: 20px;
}

.tab-link:hover {
  color: black !important;
}

.tab-link.disabled {
    color: #4D4D4D4D !important;
    .number-tab {
        border: 2px solid #4D4D4D4D;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}