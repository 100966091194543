:root {
    /*    --table-row-odd-color: #Fff;
    --table-row-even-color: #fff;
    --bs-table-striped-bg: #FB065F;*/
    --bs-success-rgb: 61, 174, 43; /* Cambia el color de verde */
}

body {
    font-family: 'Roboto';
    background-color: #f2f2f2;
    overflow-x: hidden;
}

h1 {
    font-size: 35pt;
    font-weight: bold;
}

h2 {
    font-size: 15pt;
}

h3 {
    font-size: 12pt;
}

h4 {
    font-size: 12pt;
}

h5 {
    font-size: 9pt;
}

p {
    font-size: 9pt;
}

button:disabled {
    background-color: #F2F2F2 !important;
    border-color: #6B6B6B !important;
    color: #6B6B6B !important;
    cursor: not-allowed;
}

/* Background colors */
.pg-background-green {
    background-color: $pg-green !important;
}

.pg-background-blue {
    background-color: $pg-blue !important;
}

.pg-background-pink {
    background-color: $pg-pink !important;
}

.pg-background-light-pink {
    background-color: $pg-light-pink !important;
}

.pg-background-purple {
    background-color: $pg-purple !important;
}

.pg-background-yellow {
    background-color: $pg-yellow !important;
}

.pg-background-red {
    background-color: $pg-red !important;
}

/* Colors */
.pg-color-green {
    color: $pg-green !important;
}

.pg-color-blue {
    color: $pg-blue !important;
}

.pg-color-pink {
    color: $pg-pink !important;
}

.pg-color-light-pink {
    color: $pg-light-pink !important;
}

.pg-color-purple {
    color: $pg-purple !important;
}

.pg-color-yellow {
    color: $pg-yellow !important;
}

.pg-color-red {
    color: $pg-red !important;
}

/* ESTILOS LOGIN */
.login-background-container {
    background-image: url("../img/login-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f2f2f2;
    background-position: center;
    height: 37vh;
    margin: -32px -12px 0 -51px;
}

.box-login {
    height: 94vh;
    max-width: 470px;
    color: #444950;
    font-size: 14px;
    text-align: left;
    padding-top: 3%;
}

.login-container {
    width: 100%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255);
    padding: 40px 30px;
    border: 1px solid #D6D6D6
}

.input-login {
    background: #FCFCFC !important;
    border: 0.5px solid #808184 !important;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    height: 43px;
    color: #00000080;
}

.btn-login {
    width: 156px;
    height: 52px;
}

.header-container {
    width: 100%;
}

.logo-container {
    width: 100%;
    background-color: #28a745;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
}

.version {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}

.text-login {
    color: #444950;
}

// Media queries for mobile devices LOGIN
@media (max-width: 425px) {
    .login-background-container {
        background-size: 100% 167px;
        background-color: #ffffff;
        min-height: 100vh;
        background-position: top;
    }

    .box-login {
        padding-top: 125px;
    }

    .version {
        color: #444950;
    }

    .login-container {
        background: none;
        border: none;
        padding: 0 30px;
    }

    .header-container {
        padding: 0 30px;
    }
}
/* END ESTILOS LOGIN */

/* Estilo para el menú */
#sidebar {
    background-color: #ffffff;
    margin-top: 6em;
    border-radius: .5em;
    margin-left: .5em;
    margin-bottom: .5em;
}

.nav-link {
    display: flex;
    align-items: center;
    color: #6c757d;
}

.nav-link {
    color: #4D4D4D;
    border-radius: .70rem;
    text-transform: capitalize;
}

.nav-link i.pg {
    padding-bottom: 5px;
    padding-right: 17px;
    color: #6B7280;
}

.nav-link.active {
    background-color: #6b6b6b;
    border-radius: .70rem;
    color: white;
}

.nav-link:hover {
    background-color: #6B7280;
    border-radius: .70rem;
    color: #ffffff;
    /*opacity: .7;*/
}

.nav-link:hover .pg {
    color: #ffffff;
}

.nav-link:focus {
    color: #343637;
}

.subnivel {
    padding-left: 1rem;
    /* Agrega una sangría a cada nivel de submenú */
}

.menu-arrow {
    margin-right: 0.6rem;
    margin-left: -1rem;
}

.menu-arrow-down {
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

.menu-arrow-up {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
}

.icon-menu-bars {
    width: 1.5em;
    height: 1.5em;
}



/* Estilos para la barra superior */
.navbar {
    background-color: #1B1A17;
}

.navbar-brand img {
    height: 85px;
    margin-top: -5px;
    margin-left: -15px;
    margin-bottom: -15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.no-underline {
    text-decoration: none;
}

.navegacion {
    color: #3DAE2B;
    --bs-breadcrumb-divider-color: #3DAE2B;
}

.navegacion :hover {
    color: #3DAE2B;
    --bs-breadcrumb-divider-color: #3DAE2B;
    opacity: .7;
}



.content {
    margin-left: -.7em;
}

#panel-central {
    width: 95%;
}

.btn {
    border-radius: 7px;
    text-transform: capitalize;
}

.btn-icon-grid {
    border-radius: initial;
    padding: 0px;
    margin: 0px 0.15rem !important;
}

.icon-grid {
    width: 1.5rem;
    height: 1.5rem;
}

.fa-icon-grid {
    font-size: 1.7em;
}

.btn-success {
    background-color: #3DAE2B;
    border-color: #3DAE2B;
}

.btn-danger {
    color: #ffffff !important;
    background-color: #FB065F;
    border-color: #FB065F;
}

.btn-danger:hover {
    background-color: #bd0246;
    border-color: #bd0246;
}

.btn-grey-dark, .btn-grey-dark.show {
    color: #ffffff !important;
    background-color: #6B6B6B;
    border-color: #6B6B6B;
}

.btn-grey-dark:hover, .btn-grey-dark.show:hover {
    background-color: #575252;
    border-color: #575252;
}

.btn-light {
    border-color: #9a9faa;
}

.btn-light:disabled {
    border-color: #c3c8d3;
}

.btn-success:hover {
    background-color: #349325;
    border-color: #349325;
}

@media (max-width: 767.98px) {
    .navbar-brand img {
        display: none;
    }
}

.text-center {
    text-align: center;
}

input[type="text"].disabled,
input[type="number"].disabled {
    background-color: #e9ecef;
    color: #6c757d;
    pointer-events: none;
}

.form-control {
    border: 1px solid #ECEDF0;
    background: #F5F5F5;
}

.form-control:focus {
    border: 1px solid #3DAE2B;
    border-color: #3DAE2B;
    background: #FFF;
    box-shadow: 0 0 0 0.25rem rgba(61, 174, 43, 0.25);
}

.form-control::placeholder {
    color: #BABABA;
}

.form-select::placeholder {
    color: #BABABA;
}

.form-select {
    background-color: #F5F5F5;
}

.form-select:focus {
    border: 1px solid #3DAE2B;
    border-color: #3DAE2B;
    background: #FFF;
    box-shadow: 0 0 0 0.25rem rgba(61, 174, 43, 0.25);
}

.form-select option {
    background-color: #F5F5F5;
    transition: background-color 0.3s; /* Transición suave */
}

.form-select option:checked {
    background-color: #3DAE2B;
    color: #FFF;
}

.form-select option:hover {
    background-color: #3DAE2B;
}

select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
    outline: none;
}

.select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

.select select,
.select::after {
    grid-area: select;
}

.select:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
}

select[multiple] {
    padding-right: 0;
    height: 6rem;
}

select[multiple] option {
    white-space: normal;
    outline-color: var(--select-focus);
}

.select--disabled {
    cursor: not-allowed;
    background-color: #eee;
    background-image: linear-gradient(to top, #ddd, #eee 33%);
}

.select + label {
    margin-top: 2rem;
}

.color-rojo {
    color: #FB065F;
}

/*.form-section-striped > .form-section:nth-of-type(even) {
    background-color: #fafafa;
    border-top: 0.5px solid #E5E6E8;
    border-bottom: 0.5px solid #E5E6E8;
}*/

.form-section.striped {
    background-color: #fafafa;
    border-top: 0.5px solid #E5E6E8;
    border-bottom: 0.5px solid #E5E6E8;
}

.form-section-striped > .form-section.striped-full-width:nth-of-type(even) {
    margin-right: -1rem;
    margin-left: -1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.form-section {
    padding: 1.5rem 3rem 0.5rem;
}

.titulo {
    color: #D1D5DB;
    display: flex;
    align-items: center;
    font-size: 35px;
    text-transform: capitalize;
    /*font-weight: bold;*/
}

h2.subtitulo {
    color: #9095A0;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

h3.subtitulo {
    color: #9095A0;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.icon-title {
    width: 1em;
    height: 1em;
}

.icon-breadcrum {
    width: 1.3em;
    height: 1.3em;
}

.icon-1_3em {
    width: 1.3em;
    height: 1.3em;
}

.icon-1em {
    width: 1em;
    height: 1em;
}

.icon-1_2em {
    width: 1.2em;
    height: 1.2em;
}

.icon-1_5em {
    width: 1.5em;
    height: 1.5em;
}

.icon-1_7em {
    width: 1.7em;
    height: 1.7em;
}

.text-capitalize {
    text-transform: capitalize;
}

.custom-bar {
    width: 3px;
    height: 30px;
}

.card[data-selected="false"] {
    box-shadow: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.loading-mask {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.loading-mask-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.bg-green {
    background-color: #E1FFD7 !important;
}

.bg-blue {
    background-color: #EFF6FF !important;
    color: #2252D9;
}

.bg-red {
    background-color: #FEF2F2 !important;
    color: #BE2B2B;
}

.mw-6rem {
    min-width: 6rem;
}

.mw-8rem {
    min-width: 8rem;
}

.mw-10rem {
    min-width: 10rem;
}

.mw-12rem {
    min-width: 12rem;
}

.form-label {
    margin-bottom: 0.3rem;
}

/*Estilos de Swal Alert*/
.swal2-styled {
    padding: 0.30rem 0.7rem !important;
    font-size: 0.875rem !important;
}

.swal2-actions {
    justify-content: flex-end !important;
}

.btn-cancel {
    background-color: #6B6B6B !important;
    border-color: #6B6B6B !important;
}

.btn-swal-cancel {
    background-color: #6B6B6B !important;
    border-color: #6B6B6B !important;
}

.btn-swal-success {
    background-color: #3DAE2B !important;
    border-color: #3DAE2B !important;
}
/*Alertas en los encabezado de secciones*/
.header-alert {
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
}

.header-first-alert {
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.header-alert-danger {
    background-color: #FEF2F2;
    color: #BE2B2B;
}

.header-alert-info {
    background-color: #EFF6FF;
    color: #2252D9;
}

label.requerido::after { /* Agrega el asterisco rojo al final del texto */
    content: " *";
    color: red;
}

.form-check-lg.form-check-input {
    font-size: $font-size-base * 1.25;
}

.btn-collapse-filtro {
    background-color: #292929;
    margin-top: -20px;
    margin-bottom: -20px;
    z-index: 1000;
    border-radius: 0.75rem;
    border: none;

    @extend .btn-lg;
}

.btn-collapse-filtro[aria-expanded="false"] {
    background-color: #1B1A17;
}

.btn-collapse-filtro[aria-expanded="false"]:hover {
    background-color: var(--bs-btn-hover-bg);
}

.btn-filtro {
    min-width: 8rem;

    @extend .btn-lg;
}

.collapse-fitros {
    background-color: #292929;
}


/*Elimina loader de Kendo UI*/
/*.k-loading-image {
    display: none !important;
}*/

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    z-index: 9999;
}

.spinner {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3a9c1a;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.spinner:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #55d400;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

.spinner:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00f700;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#pg-form-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    margin-bottom: 16px;
}

#pg-form-container.open {
    max-height: fit-content; /* Altura máxima del contenido */
}


.pg-color-divider-form {
    background: #FCFCFC;
}

/** ProAgro Card  **/
.pg-card-grid-info {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(290px, 1fr)); /* 4 columnas por fila */
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 15px; /* Espacio entre las tarjetas */
    overflow-x: auto;
    padding: 1em 0;
}

.pg-card {
    /* overflow: hidden; */
    position: relative;
    padding: 1rem 1rem 1rem 1rem;
    text-align: left;
    /* border-radius: 0.5rem; */
    max-width: 280px;
    border: 1px solid #E5E6E8;
    /* box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04); */
    background-color: #fff;
    min-height: 80px;
}

.pg-card .pg-card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(8, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-content: space-between;
    justify-items: stretch;
    align-items: center;
    font-size: 0.65em;
    /* column-gap: 10px; */
}

.grid-item {
    text-align: left;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #D1D5DB;
}

.pg-card-grid .grid-item:nth-child(even) {
    font-weight: normal;
    color: gray;
    padding-left: 6px;
}

.dismiss {
    position: absolute;
    right: -10px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #fff;
    color: #D1D5DB;
    border: 1px solid #D1D5DB;
    font-size: 1rem;
    font-weight: 300;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transition: .3s ease;
}

.dismiss:hover {
    background-color: #ee0d0d;
    border: 2px solid #ee0d0d;
    color: #fff;
}

.edit.edit-chip {
    position: absolute;
    right: 15px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #fff;
    color: #D1D5DB;
    border: 1px solid #D1D5DB;
    font-size: 10px;
    font-weight: 300;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transition: 0.3s ease;
}

.edit.edit-chip:hover {
    background-color: #ee0d0d;
    border: 2px solid #ee0d0d;
    color: #fff;
}

/** End ProAgro Card  **/

.header {
    padding: 1.25rem 1rem 1rem 1rem;
}

/** ProAgro select with chips **/

.pg-select-chips-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 10px 0;
    justify-content: flex-start;
}

.pg-chip-element {
    padding: 4px 6px;
    border-radius: 7px;
    border: 2px solid #3DAE2B;
    font-size: 0.8rem;
    background: #cef5c2;
    position: relative;
    animation: fadeIn 0.5s ease forwards;

    .k-chip-delete {
        position: absolute;
        background: #000000;
        border-radius: 10px;
        top: -4px;
        right: -5px;
        cursor: pointer;
        color: white;
        width: 10px;
        font-size: 0.55em;
        text-align: center;
        padding: 0.5px 2px;
    }
}

.pg-chip-element:nth-child(2n) {
    background-color: #e1ffd7;
}

.fade-out {
    animation: fadeOut 0.5s ease forwards;
}

/** ProAgro select with chips **/

/** Custom checkbox  **/

.pg-input-checkbox {
    position: relative;
    padding: 10px 0px 10px 50px;
    text-align: left;
}

.pg-input-checkbox label {
    cursor: pointer;
}

.pg-input-checkbox label:before, .pg-input-checkbox label:after {
    content: "";
    position: absolute;
    top: 50%;
    /* border-radius: 50%; PUEDE QUE UTILICEMOS EN RAIDUS*/
}

.pg-input-checkbox label:before {
    left: 12px;
    width: 30px;
    height: 30px;
    margin: -15px 0 0;
    background: #ffffff;
    border: 2px Solid #575252;
}

.pg-input-checkbox label:after {
    left: 17px;
    width: 20px;
    height: 20px;
    margin: -10px 0 0;
    opacity: 0;
    background: #3dae2b;
    transform: translate3d(-40px, 0, 0) scale(0.5);
    /* transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;*/
}

.pg-input-checkbox input[type=checkbox] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;
}

.pg-input-checkbox input[type=checkbox]:checked + label:after {
    content: '\2713';
    text-align: center;
    color: #fff;
    font-weight: bold;
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

/** END Custom checkbox **/


/** Estilo de botón para guardar y cancelar componentes **/
.buttonAction {
    width: 31px;
    height: 31px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 15px;
    box-shadow: 2px 2px 3px #999;
    transition: 0.5s;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.buttonAction.save {
    background-color: #3DAE2B;
    border: #3DAE2B;
}

.buttonAction.cancel {
    background-color: #ce3233;
    border: #ce3233;
}

.buttonAction:hover {
    transform: scale(1.1);
}

.buttonAction:active {
    background-color: #020cd1;
}
/** END Estilo de botón para guardar y cancelar componentes **/
/** END Custom checkbox **/

/* KendoDropdownList */
.item-seccion {
    width: 100%;
    border-bottom: 1px solid #E5E6E8;
    padding-bottom: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.item-seccion label {
    display: block;
    line-height: 1.3;
}

.item-seccion b {
    font-weight: 500;
}
/* Estilo cuando se selecciona una opción de cualquier elemento select */
.select-selected {
    background-color: #E1FFD7 !important;
}

.k-list .k-item.k-state-selected {
    background-color: #E1FFD7 !important; /* Fondo verde claro */
    color: #212529 !important; /* Color del texto */
}

.k-dropdown .k-input {
    background-color: #E1FFD7 !important; /* Fondo verde claro */
    color: #212529 !important; /* Color del texto */
}

.k-spacer {
    display: none;
}


/** Selector de paginado de tablas */
.k-button-flat-primary.k-button.k-selected,
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
    background-color: $pg-green !important;
    color: white;
}
/** Custom styles for Formularios */
/* Preguntas */
.item-question {
    position: relative;
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 40px 100px 40% auto;
    grid-template-rows: auto;
    gap: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    transition: 0.5s;
}

    .item-question .item-question__order {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #ECECEC;
        width: 40px;
        padding-top: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        transition: 0.5s;
    }

    .item-question .item-question__order span:nth-child(2) i {
        color: #ECECEC;
    }

.item-question .item-question__image {
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-self: center;
}

.item-question .item-question__image img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.item-question .item-question__title {
    align-self: center;
}

    .item-question .item-question__title h3 {
        margin: 0px;
    }

.item-question .item-question__details {
    align-self: center;
    padding-left: 20px;
    border-left: 2px solid #D1D5DB;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.item-question .item-question__details p {
    margin-bottom: 0.1rem;
}

    .item-question .item-question__details p.formula {
        background-color: #F6F6F6;
        padding: 10px;
        border-radius: 10px;
        margin-right: 10px;
        color: #7A7A7A;
    }

        .item-question .item-question__details p.formula span.keyword {
            color: #0066cc;
            font-weight: bold;
        }

        .item-question .item-question__details p.formula span.string {
            color: #008000;
            font-style: italic;
        }

        .item-question .item-question__details p.formula span.operator {
            color: #cc00cc;
            font-weight: bold;
        }

        .item-question .item-question__details p.formula span.parenthesis {
            color: #ff6600;
        }

        .item-question .item-question__details p.formula span.number {
            color: #ff0000;
        }

.item-question .delete {
    position: absolute;
    right: 0px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: transparent;
    color: #D1D5DB;
    border: none;
    font-size: 1rem;
    font-weight: 300;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transition: .3s ease;
}

.item-question .delete:hover {
    color: #ee0d0d;
    transform: scale(1.1);
}

.item-question .edit {
    position: absolute;
    right: 20px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: transparent;
    color: #D1D5DB;
    border: none;
    font-size: 1rem;
    font-weight: 300;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transition: .3s ease;
}

.item-question .edit:hover {
    color: #6B6B6B;
    transform: scale(1.1);
}

.item-multiple {
    position: relative;
    width: 100%;
    height: 180px;
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    transition: 0.5s;
}

    .item-multiple .item-multiple__header {
        padding-left: 1rem;
        padding-top: 1rem;
        padding-right: 1rem;
    }

    .item-multiple .item-multiple__body {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

        .item-multiple .item-multiple__body label {
            display: block;
        }

#public-form #container_main section {
    cursor: pointer;
}

#public-form .form-item {
    width: 100%;
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #E5E6E8;
}

    #public-form .form-item label {
        font-weight:500;
        margin-bottom: 1.5rem;
    }

    #public-form .form-item fieldset {
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
    }

/** End custom stiles for Formularios */

/** Custom styles for drag and drop elements */

.sortable-ghost {
    opacity: 0.3;
    cursor: move;
}

.sortable-chosen .item-question__order {
    cursor: move;
    background-color: #BABABA;
}

.sortable-chosen .item-question__order span:nth-child(2) i {
    color: #ffffff;
}

.sortable-chosen.item-question {
    background-color: #F9F9F9;
}

.sortable-drag {
    cursor: move;
}

/** End custom styles for drag and drop elements */


/*Estilos param ellipse componente defs filtros*/
#open-filterContent {
    position: fixed;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background: #292929;
    color: white;
    top: 0;
    z-index: 4;
    right: 50%;
    border: inherit;
}

.filter-container {
    position: fixed;
    background: #292929;
    padding: 2rem 4rem;
    color: white;
    display: none;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 3;
}

.filter-container .contenBtnFilter {
    display: flex;
    gap: 14px;
    justify-content: flex-end;
}

.contenBtnFilter #btnObtenerValores, .contenBtnFilter #btnResetearFormulario {
    height: 50px;
    border-radius: 5px;
    color: #ffffff;
    width: 150px;
}

#btnObtenerValores {
    background: #3DAE2B;
}

#btnResetearFormulario {
    background: #FFB800;
}
/*END Estilos param ellipse componente defs filtros*/


/* HEADER GRID TEMPLATE CSS */
.pg-grid-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 10px;
    background: #f2f2f2;
}

.pg-button {
    background: none;
    border: none;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #6B7280;
    text-decoration: none;
}

.pg-new-cotizacion {
    color: #000; /* Cambio el color del enlace para resaltarlo */
}

.pg-separator {
    height: 32px;
    background: #E5E6E8;
    width: 1px;
    margin: 0 10px; /* Añado margen para separar los elementos */
}

.pg-search-container {
    display: flex;
    align-items: center;

    i, input {
        color: #BABABA;
    }
}

.pg-icon {
    font-size: 1.4rem;
    color: #BABABA;
}

.pg-search-input {
    width: 150px;
    background: none;
    border: none;
    color: #BABABA;
    outline: none;
}

.pg-grid-header {
    display: flex;
    align-items: center;
}

.pg-grid-controls {
    display: flex;
    gap: 10px;
}

.pg-grid-button {
    width: 153px;
    height: 48px;
    border-radius: 10px;
    background: white;
    border: 0.5px solid #E5E6E8;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pg-indicator {
    height: 32px;
    width: 2px;
}

.pg-indicator-emitida {
    background-color: #A406EF;
}

.pg-indicator-pendiente {
    background: #44B15A;
}

.pg-counter {
    margin-left: 5px;
}
/* END HEADER GRID TEMPLATE CSS */

/* Inicio de estilos para alinear de manera vertical y horizontal los headers del grid */
.k-grid table thead th {
    height: auto;
    white-space: normal;
    vertical-align: middle;
}

#grid table thead th.center {
    text-align: center;
}

.k-grid table thead th.center {
    text-align: center;
}

.k-grid table tbody td i {
    font-size: 1.2rem;
}

/* Ajuste para mostrar las filas seleccionadas en color verde */
.k-grid .k-table-th.k-selected, .k-grid td.k-selected, .k-grid .k-table-td.k-selected, .k-grid .k-table-row.k-selected > td, .k-grid .k-table-row.k-selected > .k-table-td {
    color: #336637;
    background-color: color-mix(in srgb, #4aec55 25%, transparent);
}

.k-grid-header .k-grid-filter.k-active, .k-grid-header .k-header-column-menu.k-active, .k-grid-header .k-grid-header-menu.k-active, .k-grid-header .k-hierarchy-cell .k-icon.k-active {
    background-color: $pg-green;
}

/* Fin de estilos para el grid */

/*Estilos de componente PDF Viewer*/
#pdfContainer {
    display: flex;
    justify-content: center;
    max-height: 80vh;
    overflow-y: auto;
}

.pg-pdf-controls {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 16px;
    align-items: center;
    padding: 7px 10px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.0509803922);
    background: #3A3939;
    color: #fff;
    border-radius: 10px 10px 0 0;
    font-size: 12px;
    max-height: 52px;

    > div {
        width: 100%;
        padding: 0 16px;
    }
}

.pg-pdf-toolbar-input {
    padding: 2px 5px;

    &.bg {
        background: #232323;
    }
}

.pg-pdf-toolbar-icon-button {
    background: none;
    box-shadow: none;
    border: none;
    color: white;
    font-size: 12px;

    > span {
        font-size: 15px;

        &.caret {
            font-size: 10px;
        }
    }
}

.pg-pdf-controls-zoom {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 0 6px;
    align-items: center;

    > #zoomOut {
        font-size: 25px;
    }
}

.pg-pdf-separator {
    background: #707375;
    width: 1px;
    height: 23px;
}

.pdfPage {
    margin: 10px;
}

canvas {
    margin: auto;
    display: block;
}

/*END Estilos de componente PDF Viewer*/


/*ESTILOS SLIDER*/
.pg-slider {
    position: relative;
    width: auto;
    height: 6px;
    background: $slider-bg;
    margin: 20px auto;

    .slider-thumb {
        position: absolute;
        width: 20px;
        height: 20px;
        background: $slider-thumb-bg;
        border-radius: 50%;
        cursor: pointer;
        top: -7px;
        z-index: 1;

        &#thumb-left {
            left: 0px;
        }

        &#thumb-right {
            left: 280px;
        }
    }

    .slider-range {
        position: absolute;
        height: 100%;
        background: $slider-range-bg;
        left: 0px;
        width: 0px;
    }
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin: 0 auto;

    .slider-label {
        font-size: 14px;

        &#min-value {
            // Estilo adicional para el valor mínimo si es necesario
        }

        &#max-value {
            // Estilo adicional para el valor máximo si es necesario
        }
    }
}
/*END ESTILOS SLIDER*/


/* Inicio de estilos para orden de trabajo */
table.green-head tr th {
    background-color: $pg-green;
    color: #ffffff;
}
/* Fin de estilos para orden de trabajo */

/*Componente fileUploeader.js*/
.container-custom {
    width: 190px;

    .label-title {
        font-size: 12px;
        font-weight: 400;
    }

    .icon-counter {
        position: relative;

        i {
            font-size: 50px;
        }

        .file-count {
            position: absolute;
            top: 0;
            right: 1.5em;
            font-size: 10px;
            background: #b52026;
            width: 16px;
            text-align: center;
            height: 16px;
            line-height: 1.6;
            border-radius: 100%;
            color: white;
        }
    }

    .input-container {
        .file-input {
            display: none;
        }

        .btn-upload {
            background: #6B7280;
            color: white;
            width: 130px;
            border-radius: 5px;
            padding: 5px 10px;
            font-size: 12px;
            border: none;

            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    .validation-message {
        font-size: 10px;
        display: none;
    }
}

/* END Componente fileUploeader.js*/

.element {
    position: relative;
    width: 100%;
    height: 132px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    transition: 0.5s;
}

.element .delete {
    position: absolute;
    right: 0px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: transparent;
    color: #D1D5DB;
    border: none;
    font-size: 1rem;
    font-weight: 300;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transition: .3s ease;
}

.element .delete:hover {
    color: #ee0d0d;
    transform: scale(1.1);
}


/*FILE UPLOAD COMPONENT*/
span#fileUploadModal_wnd_title {
    font-family: Roboto;
    font-size: 35px;
    font-weight: 400;
    line-height: 41.02px;
    text-align: left;
    color: #D1D5DB;
}
#fileUploadModal {
    .pg.pg-policies {
        font-size: 18px;
        color: #FB065F;
    }

    .fileUpload-document-info-container {
        > div {
            padding: 2px 5px;
        }
    }

    .file-heading.file-name-heading {
        color: #9095A0;
    }

    .file-header-table {
        height: 45px;
        background: #ECF5F8;
        display: flex;
        align-items: center;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.025em;
    }

    strong.k-upload-status {
        text-align: center;
    }

    li.k-file.k-toupload,
    li.k-file.k-file-error {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .k-dropzone.k-upload-dropzone {
        display: grid;
        gap: 5px;
        grid-template-columns: 50% 50%;
        align-items: center;
        width: 100%;
        height: 83px;
        background: none;
        border: 3px dashed #E5E6E8;
        justify-content: center;
    }

    .k-upload.k-upload-async,
    .k-upload.k-upload-async.k-upload-empty {
        border: none;
    }

    .k-upload-selected,
    .k-clear-selected {
        display: none !important;
    }

    .k-actions {
        border: none;
    }

    span.k-progress.k-progressbar.k-progressbar-horizontal {
        display: none;
    }

    .k-button.k-button-md.k-rounded-md.k-button-solid.k-button-solid-base.k-upload-button {
        background: #9095A0;
        color: white;
        font-size: 1em;
        padding: 10px 20px;
    }

    span.k-dropzone-hint {
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        line-height: 12.39px;
        text-align: left;
        width: 100%;
    }

    button#closeButton {
        background: #575252;
        color: white;
    }

    button#importButton {
        background: #D1D5DB;
        color: white;
    }
}

.k-window-titlebar {
    border: none;
}

/*END FILE UPLOAD COMPONENT*/

/*colorful-card COMPONENT*/
.colorful-card {
    &__card {
        padding: 16px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 256px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__text {
        font-size: 18px;
        font-weight: 500;
    }

    &__indicator {
        font-size: 32px;
        font-weight: bold;
    }
}

.card-container {
    display: flex;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: nowrap;
    max-width: 310px;
    height: 60px;
    justify-content: space-between;
    color: #06142D;
    align-items: center;

    .card-title {
        width: 240px;
        padding: 10px;
    }

    .card-count {
        font-size: 20px;
        font-weight: bold;
        flex: 1;
        min-width: 70px;
        background: #ffffff54;
        height: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.card-container-green {
    background-color: #d1e7dd;
}

.card-container-blue {
    background-color: #d1d8ff;
    color: #2d3cd3;
}

.card-container-yellow {
    background-color: #fff3cd;
}

.card-container-pink {
    background-color: #f8d7da;
}

.card-container-cyan {
    background-color: #cff4fc;
}
/*END colorful-card UPLOAD COMPONENT*/
.k-grid-mh {
    min-height: 300px;
}

.test-container {
    padding: 10px;
    background-color: lightgray;
    // Estilos para pantallas pequeñas (xs)
    @include respond-to(xs) {
        background-color: lightblue;
        padding: 20px;
    }
    // Estilos para pantallas medianas (md)
    @include respond-to(md) {
        background-color: lightgreen;
        padding: 30px;
    }
    // Estilos para pantallas grandes (lg)
    @include respond-to(lg) {
        background-color: lightcoral;
        padding: 40px;
    }
}
