$font-family-sans-serif:  Roboto,system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-size-base: .75rem !default; // Assumes the browser default, typically `16px`
$table-color: #4D4D4D; // color de texto para las tablas
$input-color: #4D4D4D; // Cambia "blue" por el color que prefieras.

//Checks y radios
$form-check-input-checked-bg: #3DAE2B !default;
$form-check-input-checked-border-color: #3DAE2B !default;
$form-check-input-checked-bg-color: #3DAE2B !default;
$focus-ring-color: rgba(#3DAE2B, .25) !default;

$link-success: #3DAE2B !default; // Cambia el color del verde

$enable-negative-margins: true; //Margenes negativos


//ProAgro Colors
$pg-green: #349325;
$pg-background: #1B1A17;
$pg-dark: #333331;
$pg-medium-dark: #575252;
$pg-medium: #6B6B6B;
$pg-medium-light: #6B7280;
$pg-light: #F1F0F5;
$pg-yellow: #FFB800;
$pg-pink: #FB065F;
$pg-light-pink: #FF9090;
$pg-blue: #05B6D3;
$pg-purple: #A406EF;
$pg-light-gray: #F2F2F2;
$pg-white: #FFFFFF;
$pg-off-white: #ECEDF0;
$pg-red: #FF0000;
$slider-bg: #ddd;
$slider-thumb-bg: #3ECE0C;
$slider-range-bg: #3DAE2B;



//BREAKPOINTS MEDIA QUERYS
$breakpoints: ( xs: 480px, sm: 768px, md: 1024px, lg: 1280px, xl: 1440px );

@mixin respond-to($size) {
    @media (min-width: map-get($breakpoints, $size)) {
        @content;
    }
}